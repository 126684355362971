.login {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

@media screen and (min-width: 700px) {
    .login {
        width: 400px;
    }

    .login-block {
        padding: 10px 25px;
        border-radius: 5px;
        border: solid 1px #dddddd;
    }
}

.no-account {
    display: flex;
    align-items: center;
    color: #777777;
    margin-top: 30px;
    margin-bottom: 20px;
}

.no-account hr {
    flex-grow: 1;
    height: 0;
}

.no-account p {
    padding: 0 5px;
}

.login-text p {
    padding: 10px 0;
}

.login-text input[type=checkbox] {
    width: auto;
}

.login-text span {
    font-weight: bold;
}